import { dev } from "$app/environment";
import { env } from "$env/dynamic/public";
import { handleErrorWithSentry } from "@sentry/sveltekit";
import type { ErrorEvent, EventHint } from "@sentry/types";
import * as Sentry from "@sentry/sveltekit";
import { analyticsService } from "$lib/services/analytics-service.js";
import { captureConsoleIntegration } from "@sentry/integrations";

console.log("client: " + __VERSION__);

Sentry.init({
  enabled: !dev,
  dsn: "https://20be89a91cb2c924588474c68b8e5acc@o4506513304846336.ingest.sentry.io/4506546923372544",
  integrations: [captureConsoleIntegration({ levels: ["error"] })],
  tunnel: "/sentry",
  environment: env.PUBLIC_SENTRY_ENVIRONMENT,
  release: __VERSION__ ?? "unknown",
  tracesSampleRate: 0.01,
  profilesSampleRate: 0.2,
  replaysSessionSampleRate: 0,

  // Ignore list based off: https://gist.github.com/1878283
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
  ],

  // Ignore list based off: https://gist.github.com/1878283
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSend: (event: ErrorEvent, hint: EventHint) => {
    const frames = event.exception?.values?.[0]?.stacktrace?.frames;
    if (frames) {
      const hasChromeExtension = frames.some((frame) => frame?.filename?.includes("chrome-extension"));
      if (hasChromeExtension) {
        return null;
      }
    }

    return event;
  },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

analyticsService.initialize(env.PUBLIC_AMPLITUDE_API_KEY);
